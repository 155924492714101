/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Component, Fragment } from 'react';
//import PropTypes from "prop-types";
import { Link } from "gatsby";
import "../../../cms/web/dist/styles/procurement.css";
import "layout.css"

import '../../styles/global.scss';
import Footer from "./footer";


import getFirebase, { FirebaseContext } from '../../components/Firebase';
import withAuthentication from '../../components/Session/withAuthentication';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faDownload, faUserAlt, faListUl, faEnvelopeOpen, faSignIn, faUserPlus } from '@fortawesome/pro-light-svg-icons';

import { AuthUserContext } from '../../components/Session';
import * as ROUTES from '../../constants/routes';
import SignOutButton from '../../components/SignOut';

import header from '../../styles/header.module.scss';

import  Image from './image';
// test

class Layout extends Component {
  constructor(){
    super();
    this.state = {
      firebase: null,
    };
    this.node = React.createRef();
  }
  componentDidMount() {
    const app = import('firebase/app');
    const auth = import('firebase/auth');
    const database = import('firebase/database');

    Promise.all([app, auth, database]).then(values => {
      const firebase = getFirebase(values[0]);

      this.setState({ firebase });
    });

  
    // var closeMenu = event => {
    //     event.preventDefault();
    //     body.classList.toggle('menu-open');
    // }
  


    var body = document.querySelector('body');
    document.getElementById('burger').onclick = function(e){
        e.preventDefault();
        body.classList.toggle('menu-open');
    };
    document.getElementById('navigation').onclick = function(e){
        body.classList.toggle('menu-open');
    };

    console.log("host name: "+window.location.hostname);
    if(window.location.hostname === "ceca-mcp.com"){
      const scriptVars = document.createElement("script");
      scriptVars.innerHTML = 'sc_project = 12147381; sc_invisible = 1; sc_security = "61c517a5"; sc_https = 1;';
      document.body.appendChild(scriptVars);

      const script = document.createElement("script");
      script.src = "https://www.statcounter.com/counter/counter.js";
      document.body.appendChild(script);
    }
  }

  // handleClick = event =>{

     
  // //   if(event.target !== document.getElementById('navigation') && event.target !== document.getElementById('close') && event.target !== document.getElementById('burger') && event.target !== document.getElementById('listItem')){
  // //     document.body.classList.remove('menu-open');
      
  // //   }
  // //   // else{
  // //   //    console.log("click Handled");
  // //   // }
    
  //  }
  


  render() {

      return (
        <FirebaseContext.Provider value={this.state.firebase}>
          <AppWithAuthentication {...this.props} forwardRef={node => this.node = node} onClick={this.handleClick} />
        </FirebaseContext.Provider>
      );
    }
}


  const AppWithAuthentication = withAuthentication(({ children }) => (
    <Fragment>
    <div className="main-container">
    <header id="masthead">

        <div className="wrapper">
           <Link to={ROUTES.INDEX} className={header.imgLink} id="mast-logo"> <Image/> </Link>
           <a href="#navigation" aria-controls="navigation" arai-expanded="false" aria-label="Open and close navigation" id="burger"><span aria-hidden="true" id="close"></span></a>
        </div>
        
        <nav id="navigation" aria-hidden="false" tabIndex="1">

           <AuthUserContext.Consumer>
             {authUser =>
               authUser ? (

                      <ol className={header.list}>
                        <li id="listItem" className={header.listItem}><div><Link className={header.colorLink} to={ROUTES.INDEX}><FontAwesomeIcon className="search" icon={faSearch} aria-hidden="true" />Browse All Manuals</Link></div></li>
                        <li id="listItem" className={header.listItem}><Link className={header.colorLink} to={ROUTES.BOOKMARKED}><FontAwesomeIcon className="search" icon={faDownload} aria-hidden="true" />View Saved Manuals</Link></li>
                        <li id="listItem" className={header.listItem}><Link className={header.colorLink} to={ROUTES.ACCOUNT}><FontAwesomeIcon className="search" icon={faUserAlt} aria-hidden="true" />View Profile</Link></li>
                        <li id="listItem" className={header.listItem}><Link className={header.colorLink} to={ROUTES.TERMS}><FontAwesomeIcon className="search" icon={faListUl} aria-hidden="true" />EULA</Link></li>
                        <li id="listItem" className={header.listItem}><Link className={header.colorLink} to={ROUTES.CONTACT}><FontAwesomeIcon className="search" icon={faEnvelopeOpen} aria-hidden="true" />Contact Support</Link></li>
                        <li id="listItem" className={header.listItem}><SignOutButton /></li>
                      </ol>

               ) : (
                    <ol className={header.list}>
                    <li id="listItem" className={header.listItem}>
                      <Link to={ROUTES.SIGN_IN}><FontAwesomeIcon className="search" icon={faSignIn} aria-hidden="true" />Sign In</Link>
                    </li>
                    <li id="listItem" className={header.listItem}>
                      <Link to={ROUTES.SIGN_UP}><FontAwesomeIcon className="search" icon={faUserPlus} aria-hidden="true" />Sign Up</Link>
                    </li>
                    </ol>
               )
             }
           </AuthUserContext.Consumer>
            </nav>
            
    </header>
      <section id="content" className="page">

          <main>{children}</main>

      </section>
      <div id="push"></div>
    </div>
    <Footer/>

        {/*<!-- Default Statcounter code for CECA*/}
          
        {/*<!-- End of Statcounter Code --></noscript>*/}
    </Fragment>
));

export default Layout;
